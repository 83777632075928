.genomeBase {
    padding: 1em;
}

.genomes {
    padding: 1em;
}

.genomeSelector {
    margin: .5em;
    padding: .5em;
    background-color: blue;
}